// ----------------------------------------------------------------------
// Font
// ----------------------------------------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: $default-font;
  font-size: 100%;
  background-color: #fff;
  @include font-size(13);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: #2f9fe9;
}

// ----------------------------------------------------------------------
// heading
// ----------------------------------------------------------------------
.head-h2 {
  @include font-size(32);
  font-weight: 600;
  color: $primary;
  line-height: 4rem;

  @include max-screen($screen-md) {
    @include font-size(25);
  }

  @include max-screen($screen-xs) {
    @include font-size(20);
  }
}

.head-h3 {
  @include font-size(18);
  font-weight: 600;
  color: $primary;
  text-transform: uppercase;

  @include max-screen($screen-md) {
    @include font-size(15);
  }
}

.head-sm {
  @include font-size(20);
  font-weight: 500;
  color: $primary;
  margin-bottom: 6rem;
}

// ----------------------------------------------------------------------
// Button
// ----------------------------------------------------------------------
button:disabled,
button[disabled],
.btn:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.btnPrimary {
  padding: 1rem;
  height: 55px;
  background-color: $primary;
  line-height: 40px;
  @include font-size(20);
  font-weight: 600;
  border-color: $primary;
  min-width: 430px;
  max-width: 100%;
  font-family: $default-font;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  position: relative;

  @include max-screen($screen-xs) {
    min-width: 100%;
  }

  .keyHot {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    line-height: 15px;
    @include font-size(10);
    font-weight: 600;

    @include max-screen($screen-xs) {
      display: none;
    }

    i {
      @include font-size(15);
    }
  }
}

.invalid-feedback {
  @include font-size(15);
  padding: 1rem;
  font-weight: 600;
}
// ----------------------------------------------------------------------
// Modal Validate
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// checked
// ----------------------------------------------------------------------

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

// ----------------------------------------------------------------------
// Loading
// ----------------------------------------------------------------------
.loadingSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 2rem;
  font-weight: 500;
  color: #12122d;
}

.font-montserrat {
  font-family: "Montserrat" !important;
  font-weight: 700 !important;

  .font-800 {
    font-weight: 800 !important;
    letter-spacing: 0.2rem;
  }

  span {
    font-family: "Montserrat" !important;
    font-weight: 700 !important;
  }
}