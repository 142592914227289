@charset "utf-8";
$default-font: 'Montserrat', sans-serif;

//------------------------------------------------------------
//    Screen
//------------------------------------------------------------
$width-site: 1200px;

//Responsive
$screen-tb: 1279px;
$screen-md: 1023px;
$screen-sm: 767px;
$screen-xs: 480px;

//------------------------------------------------------------
//    Colors
//------------------------------------------------------------
$primary: #12122d;